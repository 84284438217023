// Given that we refresh every 1 second, the following refers to the number of seconds
// that we will wait before performing a reindex.
//
// Don't be too long so that when someone deletes a card outside of the Power-Up, it won't
// be too long before the card is deleted from the Power-Up.
const REINDEX_WAIT_TIME = 2 // In practice this allows 3 retries (2 more retries after the initial request).
const REINDEX_WAIT_TIME_IN_MILLIS = (REINDEX_WAIT_TIME - 1) * 1000

export default class {
  static requestReindex(storage, actualLength, expectedLength) {
    const currentTimestamp = new Date().getTime()
    if (storage.createdAt == null) {
      this._setReindexStatus(storage, currentTimestamp)
    }

    const retryCount = storage.retryCount += 1

    // The goal here is to wait around X seconds before reindexing because most of the time the complete
    // data will arrive eventually so we want to prevent unnecessary reindexing.
    //
    // - The retryCount check is important because a machine might have unstable connection (frequent
    //   disconnection), in which case a simple timestamp check will not be safe because the
    //   timestamp would easily have gone past X seconds without giving the machine enough time
    //   to get the "Authoritative" data.
    // - The createdAt check is necessary just in case `cache()` gets called from multiple
    //   places incorrectly (even though `cache()` is designed to be called from one place only per page).
    //   In such a case, the retryCount would go up too quickly and will hit the threshold before
    //   X seconds have elapsed.
    // - During testing, it is observed that under normal circumstances, only 1 retry is needed. It is very
    //   rare that a second retry is needed.
    // - Nevertheless it is still possible that an unnecessary reindexing happens, e.g. if the peer machine
    //   happens to be doing a lot of cache locking and hence not receiving the complete data fast enough,
    //   but it's fine since reindexing is not destructive.
    // - More over, reindexing is required to restore inconsistencies from deleting cards outside of the
    //   Power-Up. This is the only legitimate scenario where reindexing is mandatory. As much as possible,
    //   we should avoid reindexing for operations that the Power-Up has control of.
    if (retryCount > REINDEX_WAIT_TIME &&
      currentTimestamp - storage.createdAt > REINDEX_WAIT_TIME_IN_MILLIS) {
      this._setReindexStatus(storage, null)
      return true
    }
    console.debug(`Requesting reindex for updating ${actualLength} -> ${expectedLength} cards (${retryCount} retries)`)
    return false
  }

  static cancelReindex(storage) {
    if (storage.createdAt != null) {
      const currentTimestamp = new Date().getTime()
      console.debug("Reindex request canceled", currentTimestamp)
      this._setReindexStatus(storage, null)
    }
  }

  static _setReindexStatus(storage, timestamp) {
    Object.assign(storage, { createdAt: timestamp, retryCount: 0 })
  }
}
